@import "~antd/dist/antd.css";

body {
  font-family: Arial, Helvetica, sans-serif !important;
}

.ant-table {
  color: #333;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #1486A2 !important;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 32px;
  line-height: 32px;
}

.ant-table-pagination.ant-pagination {
  margin: 0;
  width: 100%;
  background-color: #fafafa;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.ant-card-body {
  padding: 15px;
}